import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface CardRowProps {
    id?: string;
    label?: string | JSX.Element;
    value?: string | JSX.Element;
    className?: string;
}

export const CardRow = ({
    id,
    label,
    value,
    className = '',
    children
}: React.PropsWithChildren<CardRowProps>) => {
    const labelId = useMemo(() => id ?? uuidv4(), [id]);
    return (
        (label && value) ?
            <div className={`d-flex flex-row justify-content-between align-items-center gap-4 ${className}`}>
                <label id={labelId}>
                    {label}
                </label>
                <div aria-labelledby={labelId}>
                    {value}
                </div>
            </div>
            :
            <div className={`d-flex flex-row justify-content-between align-items-center gap-4 ${className}`}>
                {children}
            </div>
    );
};

interface CardProps {
    title?: string | JSX.Element;
    padTitle?: 'p-1' | 'p-2' | 'p-3' | 'p-4' | 'p-5'
    padBody?: 'p-1' | 'p-2' | 'p-3' | 'p-4' | 'p-5'
    titleHeader?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    titleSize?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
    border?: boolean;
    cardStyle?: React.CSSProperties;
    bodyClassName?: string;
}

export const Card = ({
    title,
    padTitle = 'p-3',
    padBody = 'p-3',
    titleHeader = 'h2',
    titleSize = 'small',
    border = true,
    cardStyle = {},
    bodyClassName = '',
    children,
}: React.PropsWithChildren<CardProps>) => {
    const body = (
        <div>
            {title && (
                <>
                    <hzn-heading class={padTitle} as={titleHeader} size={titleSize}>
                        {title}
                    </hzn-heading>
                </>
            )}
            <div className={`${padBody} ${bodyClassName}`}>{children}</div>
        </div>
    );
    return border ? (
        <hzn-card style={cardStyle} elevation="flat" border-radius="medium" padding="none">
            {body}
        </hzn-card>
    ) : (
        body
    );
};
