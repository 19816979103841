import '@horizon/icons/individual/hzn-plus';
import { Card } from '@src/components/Card';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePageTitle } from '@src/hooks/usePageTitle';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import { BasketDto } from '@src/api/CheckoutApi';
import { PaymentAddCard } from './PaymentAddCard/PaymentAddCard';
import { PaymentCard } from './PaymentCard';
import { getActivePayments, getNegativeEquityBalance, getPartyId, getTotalEquity } from '@src/utils/BasketFns';
import { ProcessCashModal } from '../ProcessPayment';
import { ProcessDebitModal } from '../ProcessPayment/Debit/ProcessDebitModal';
import { ProcessCheckModal } from '../ProcessPayment/Check/ProcessCheckModal';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment } from '@src/store/PaymentSlice';
import { ProcessCertifiedFundsModal } from '../ProcessPayment/CertifiedFunds/ProcessCertifiedFundsModal';
import { HorizonAlert } from '@src/components/horizon/HorizonAlert';

export interface PaymentProps {
    basket: BasketDto;
}

export const NegativeEquity = ({ basket }: PaymentProps) => {
    usePageTitle('Payment');
    const { basketId } = useParams();
    const [addingPayment, setAddingPayment] = useState<boolean>(false);
    const { payment } = useAppSelector(selectPayment);

    const partyId = useMemo(() => getPartyId(basket), [basket]);
    const totalEquity = useMemo(() => getTotalEquity(basket), [basket]);
    const payments = useMemo(() => basket.payments.filter(p => p.direction === 'INBOUND' && ["NEW", "PAID", "VOIDED", "REFUNDED"].includes(p.status)), [basket]);
    const balance = useMemo(() => getNegativeEquityBalance(basket), [basket]);

    const completed = Math.abs(totalEquity) === basket.customerProcessedPaymentAmount;

    return (
        <hzn-stack space="large">
            <hzn-text weight="bold" class="pt-2 ps-3 pe-3" role="heading" aria-level={3}>
                Balance & Process Payments
            </hzn-text>
            <hzn-stack space="small" class="pb-2 ps-4 pe-4">
                <hzn-text>1. Add payment methods to balance the full total due amount.</hzn-text>
                <hzn-text>2. Process all payments before moving on to the next step.</hzn-text>
            </hzn-stack>
            {payments.map(payment => (
                <PaymentCard
                    key={`${payment.id}`}
                    basketId={basketId}
                    partyId={partyId}
                    payment={payment}
                    balance={balance}
                />
            ))}
            {addingPayment && (
                <Card>
                    <hzn-text weight="bold" class="pb-4" role="heading" aria-level={3}>
                        Add Payment Method
                    </hzn-text>
                    <PaymentAddCard
                        basketId={basketId}
                        partyId={partyId}
                        balance={balance}
                        onClose={() => setAddingPayment(false)}
                    />
                </Card>
            )}
            <HorizonAlert
                show={completed}
                tone="info"
                heading="Customer receipt will print automatically."
                sub-heading="An itemized receipt for all processed payments will print when you continue to the next step."
                className="ps-3 pe-3"
            />
            <HorizonButton
                className="ps-3"
                variant="secondary"
                disabled={addingPayment || !balance}
                onClick={() => {
                    setAddingPayment(true);
                }}
            >
                Add Payment Method
                <hzn-icon-plus slot="trailing-icon" size="large" />
            </HorizonButton>
            {payment && (
                <>
                    <ProcessCashModal show={payment.paymentType === 'CASH'} />
                    <ProcessDebitModal show={payment.paymentType === 'DEBIT'} />
                    <ProcessCheckModal show={payment.paymentType === 'CHECK'} />
                    <ProcessCertifiedFundsModal show={payment.paymentType === 'CERTIFIED_FUNDS'} />
                </>
            )}
        </hzn-stack>
    );
};
