import { BasketDto, BasketLineItemDto, CashPaymentDto, PaymentDirection, PaymentDto, PaymentStatus, PaymentType } from '@src/api/CheckoutApi';

const firstAppraisalPurchaseLineItem = (basket: BasketDto): BasketLineItemDto =>
    basket.lineItems?.find(item => item.lineItemType === 'APPRAISAL_ITEM');

export const getVehicleName = (basket: BasketDto): string =>
    firstAppraisalPurchaseLineItem(basket)?.name ?? "Not Found";

export const getVehicleVin = (basket: BasketDto): string =>
    firstAppraisalPurchaseLineItem(basket)?.vin ?? "Not Found";

export const getVehicleStockNumber = (basket: BasketDto): string =>
    firstAppraisalPurchaseLineItem(basket)?.itemId ?? "Not Found";

export const getPartyId = (basket: BasketDto): string =>
    basket.lineItems[0].participants.find(p => p.roles.includes('SELLER')).id;

export const getTitleHolderName = (basket: BasketDto): string => 
    basket.lineItems[0].participants
    .filter(p => p.roles.includes('TITLE_HOLDER'))
    .map(p => p.name)
    .join(' and ');

const ActivePaymentStatuses: PaymentStatus[] = ['AUTHORIZED', 'NEW', 'PAID', 'PROCESSING'];

const getPaymentsOfDirectionAndType = (basket: BasketDto, direction: PaymentDirection, statuses: PaymentStatus[]) : PaymentDto[] =>
    (basket.payments ?? []).filter(p => p.direction == direction && statuses.includes(p.status));

const totalPayments = (payments: PaymentDto[]) => payments.reduce((acc, p) => acc + (p.amount ?? 0), 0);

export const getActivePayments = (basket: BasketDto) =>
    getPaymentsOfDirectionAndType(basket, 'INBOUND', ActivePaymentStatuses);

export const getUnprocessedPayments = (basket: BasketDto) => getPaymentsOfDirectionAndType(basket, 'INBOUND', ['NEW']);

export const getProcessedPaymentsTotal = (basket: BasketDto) =>
    totalPayments(getPaymentsOfDirectionAndType(basket, 'INBOUND', ['PAID']));

export const getTotalEquity = (basket:BasketDto) =>
    basket.lineItems.reduce((acc, curr) => acc + (curr.equity ?? 0), 0);

export const getNegativeEquityBalance = (basket: BasketDto): number => {
    // if the customer equity is positive, there is no balance.
    const totalEquity = getTotalEquity(basket);
    if (totalEquity >= 0) return 0;

    // total up all the inbound unprocessed payments (cash, debit, etc.)
    const sumOfUnprocessedPayments = totalPayments(getActivePayments(basket));

    // the customer equity will be negative, e.g. -5000.
    // the unprocessed payment sum will be positive, e.g. 2000
    // the balance should be positive. math.abs(-5000 + 2000) => 3000
    return Math.abs(getTotalEquity(basket) + sumOfUnprocessedPayments);
};

export const getChangeGivenAmount = (basket: BasketDto) : number => 
    getPaymentsOfDirectionAndType(basket, 'INBOUND', ['PAID'])
    .filter(p => p.paymentType === "CASH")
    .reduce((acc, p) => acc + (p as CashPaymentDto).cashCollected - (p as CashPaymentDto).amount, 0);
