import '@horizon/icons/individual/hzn-trash';
import '@horizon/icons/individual/hzn-pencil-underline';
import { PaymentReadCard } from '@src/pages/BasketCheckout/Payment/PaymentReadCard/PaymentReadCard';
import React, { useState } from 'react';
import { PaymentDto, useDeletePaymentMutation } from '@src/api/CheckoutApi';
import { Suspense } from '@src/components/Suspense';
import { PaymentAddCard } from './PaymentAddCard/PaymentAddCard';
import { FlexColumn, FlexRow } from '@src/components/Flex';
import { PaymentTypeName } from '@src/utils/Formatting';

interface PaymentCardProps {
    basketId: string;
    partyId: string;
    payment?: PaymentDto;
    balance: number;
}

export const PaymentCard = ({ basketId, partyId, payment, balance }: PaymentCardProps) => {
    const [isEditing, setIsEditing] = useState(!payment);

    const [deletePayment, deletePaymentStatus] = useDeletePaymentMutation();

    const onDeletePayment = () =>
        deletePayment({ basketId, paymentId: payment.id })
            .unwrap()
            .catch(err => console.log('Delete payment error', err));

    const title = payment ? PaymentTypeName(payment.paymentType) : 'New Payment Method';

    return (
        <hzn-card elevation="flat" border-radius="medium" padding='none'>
            <FlexColumn className="gap-2 ps-3 pe-3 pb-3">
                <FlexRow className="align-items-center" style={{minHeight:"3.5rem"}}>
                    <hzn-text weight="bold" role="heading" aria-level={3}>
                        {title}
                    </hzn-text>
                    <div className="ms-auto">
                        <Suspense suspended={deletePaymentStatus.isLoading || deletePaymentStatus.isSuccess}>
                            {payment?.status == 'NEW' && !isEditing && (
                                <>
                                    <hzn-icon-button
                                        label={`Edit ${title}`}
                                        onClick={() => setIsEditing(true)}
                                    >
                                        <hzn-icon-pencil-underline />
                                    </hzn-icon-button>
                                    <hzn-icon-button
                                        tone="critical"
                                        label={`Delete ${title}`}
                                        onClick={onDeletePayment}
                                    >
                                        <hzn-icon-trash />
                                    </hzn-icon-button>
                                </>
                            )}
                            {payment?.status === "PAID" && (
                                <hzn-badge label="Paid" intense tone="positive"/>
                            )}
                            {(payment?.status === "VOIDED" || payment?.status === "REFUNDED") && (
                                <hzn-badge label="Voided" intense tone="critical"/>
                            )}
                        </Suspense>
                    </div>
                </FlexRow>
                {isEditing && (
                    <PaymentAddCard
                        basketId={basketId}
                        partyId={partyId}
                        payment={payment}
                        balance={balance}
                        onClose={() => setIsEditing(false)}
                    />
                )}
                {!isEditing && <PaymentReadCard payment={payment} basketIsBalanced={!balance} />}
            </FlexColumn>
        </hzn-card>
    );
};
